import { createRoot } from 'react-dom/client'
import { lazy, StrictMode, Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { GlobalStyle } from 'utils/GlobalStyle'
import { QueryClientProvider, QueryClient } from 'react-query'
import { kcContext as kcLoginThemeContext } from './keycloak-theme/login/kcContext'
import { SuisseIntlFont } from './assets/SuisseIntlFont/SuisseIntlFont'
import { MediaQueryProvider } from './providers/MediaQueryProvider'
import { TagManagerProvider } from './providers/TagManagerProvider'

const KcLoginThemeApp = lazy(() => import('./keycloak-theme/login/KcApp'))
const App = lazy(() => import('./App'))

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

createRoot(document.getElementById('root')!).render(
  // @ts-ignore
  <StrictMode>
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <MediaQueryProvider>
          <TagManagerProvider>
            <HelmetProvider>
              <GlobalStyle />
              <SuisseIntlFont />
              {(() => {
                if (kcLoginThemeContext !== undefined) {
                  return <KcLoginThemeApp kcContext={kcLoginThemeContext} />
                }

                return <App />
              })()}
            </HelmetProvider>
          </TagManagerProvider>
        </MediaQueryProvider>
      </QueryClientProvider>
    </Suspense>
  </StrictMode>,
)
